const dateParser = () => {
    document.arrive('.scs-paragraph-text', { existing: true }, (elementWithDates) => {
        if (SCSRenderAPI.renderMode !== 'edit') {
            // find text in the format [[1990-09-29]], and replace it with the number of years since that date
            const strSearch = elementWithDates.innerHTML.match(/\[\[[0-9]{4}-[0-9]{2}-[0-9]{2}\]\]/g)
            if (strSearch) {
                strSearch.forEach((str) => {
                    const date = str.replace(/\[|\]/g, '')
                    //replacing the numbers with 365.25 days
                    const years = Math.floor((new Date() - new Date(date)) /  (365.25 * 24 * 60 * 60 * 1000)) 
                    elementWithDates.innerHTML = elementWithDates.innerHTML.replace(str, years) 
                })
            }
        }
    })

}

export default dateParser
