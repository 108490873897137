const renderShare = () => {
    document.arrive('#share-widget', {existing: true, onceOnly: true}, (elShareWidget) => {
        const strPageTitle = document.title
        document.querySelector('a#share-linkedin').href = `https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${encodeURIComponent(window.location.href)}`;
        document.querySelector('a#share-twitter').href = `https://twitter.com/intent/tweet?text=${strPageTitle}&url=${encodeURIComponent(window.location.href)}`;
        document.querySelector('a#share-email').href = `mailto:?subject=Berkshire%20Hathaway%20Energy&body=You've%20been%20invited%20to%20learn%20more%20about%20Berkshire%20Hathaway%20Energy:%0D%0A%0D%0A${strPageTitle}%0D%0A${encodeURIComponent(window.location.href)}`;
    
        document.querySelector("#share-widget div").addEventListener("click", () => {
            document.querySelector('#share-widget .share-popup-window').classList.toggle("open")
        })
        document.querySelector('#share-widget .close').addEventListener("click", () => {
            document.querySelector('#share-widget .share-popup-window').classList.remove("open")
        })
    })
}

export default renderShare
