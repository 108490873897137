/* 
 * Setup imports and global vars
 *
 */
// import {} from "./sentry";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {} from "arrive" // https://github.com/uzairfarooq/arrive
import renderBreadcrumbs from "./breadcrumbs"
import renderShare from "./share"
import dateParser from "./date-parser"

const supportsContainerQueries = "container" in document.documentElement.style;
if (!supportsContainerQueries) {
	require("container-query-polyfill");
}

let bDebug = false
const startRenderApiDependents = () => {
  document.title = SCSRenderAPI.pageModel.properties.title ?
                    SCSRenderAPI.pageModel.properties.title :
                    SCS.structureMap[SCS.navigationCurr].name
  window.ChartDataLabels = ChartDataLabels
  renderBreadcrumbs()
  renderShare()
  dateParser()
}

if (typeof SCSRenderAPI !== 'undefined') {
  if (bDebug) console.log('scsrenderapi is ready')
  startRenderApiDependents()
} else {
  document.addEventListener('scsrenderstart', () => {
    if (bDebug) console.log('scsrenderapi event fired')
    startRenderApiDependents()
  })
}